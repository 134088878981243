import React, { useState } from 'react';
import Countdown from 'react-countdown';
import useMain from '../../../../hooks/useMain';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';

const Festivals = () => {
	const { initData, uiInfo } = useMain();
	const { saleEvents } = initData;
	const { isLessThanMD } = uiInfo;

	const firstDay = saleEvents[0];
	const lastDay = saleEvents[saleEvents.length - 1];

	const [isFinished, setIsFinished] = useState(Date.now() >= lastDay.endTime);

	if (Date.now() < firstDay.startTime) return null;
	return (
		<div className='row'>
			<div className='col-sm-12'>
				<TextCard
					className='newSeason'
					text={
						<div className='row gy-4 align-items-center text-light'>
							<div className='col-md-5 col-12 text-center'>
								<Image
									className='w-75'
									fileName='festival/newSeason/title.svg'
								/>
								<div className='mt-3'>
									{isFinished ? (
										<h4>Event is ended.</h4>
									) : (
										<div>
											<Countdown
												className='fs-2'
												onComplete={() => setIsFinished(true)}
												date={lastDay.endTime}
											/>
										</div>
									)}
								</div>
							</div>
							<div className='col-md-3 col-12 text-center'>
								<Image
									className={`w-${isLessThanMD ? '50' : '75'}`}
									fileName='festival/newSeason/logo.svg'
								/>
							</div>
							<div className='col-md-3 col-12 text-center'>
								<Image
									className={`w-${isLessThanMD ? '75' : '50'}`}
									fileName='festival/newSeason/date.svg'
								/>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default Festivals;
